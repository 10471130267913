import { service } from '../CONF.js'

export function getCustomerList(type) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/home/customerList',{
                params: {
                    type: type,
                    authFree: true
                }
            })
        }))
    })
}